import Container from 'react-bootstrap/Container'
import React, {useMemo, useEffect, useState} from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import MemberShip from '../models/Membership'
import MobTable from '../components/MobTable'
import Logo from '../assets/logo.png'
import ReminderDebtModal from '../components/Modals/ReminderDebtModal'
import Member from "../models/Member";
import MembershipModal from "../components/MembershipModal"
import profilePicture from "../assets/default_user.png";
import moment from "moment"
import InvoiceDetailModal from "../components/Modals/InvoiceDetailModal";
import {Badge} from "react-bootstrap";
import MemberCardModal from '../components/Modals/MemberCardModalV2'
import UpgradeMembershipModal from '../components/Modals/UpgradeMembershipModal'
import Alert from '../components/Alert'
import Swal from 'sweetalert2'
import TextTranslator from "../util/TextTranslator";
import MemberCard from '../models/MemberCard'


const releaseDate = new Date("2024-01-01")
const releaseGracePeriod = (new Date("2024-06-30"))
const GRACE_PERIOD = new Date("2025-03-31")

function InvoicePage(props) {

  const [profileData, setProfileData] = useState({})
  const [activeMemberData, setActiveMemberData] = useState(null)
  const [activeExtensionData, setActiveExtensionData] = useState(null)
  const [latestExtensionData, setLatestExtensionData] = useState(null)
  const [gracePeriod, setGracePeriod] = useState('')

  const [memberCarData, setMemberCardata] = useState(null)

  const [latestHistoryRegardlessOfValidity, setLatestHistoryRegardlessOfValidity] = useState([])

  const [showMemberCard, setShowMemberCard] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  const [selectedInvoiceDetail, setSelectedInvoiceDetail] = useState(null)
  const [isValidMembership, setIsValidMembership] = useState(true)

  const fetchSelfData = async () => {
    let memberModel = new Member()
    try {
      let result = await memberModel.getMemberData()
      if (result?.membership?.length > 0) {
        const validResult = result?.membership[0]
        if (moment(result?.grace_period) <= moment()) {
          const dueDateMembership = moment(validResult?.valid_until).add(30, 'days').format('DD/MM/YYYY');
          // setIsValidMembership(false)
          // setValidateMsg(`Harap selesaikan pembayaran sebelum tanggal ${dueDateMembership} atau fitur fitur anda akan dikunci`)
        } else if (moment() > moment(result?.grace_period)) {
          setIsValidMembership(false)
          setValidateMsg(`Akun Sihapei Saudara telah dinonaktifkan. Harap selesaikan tunggakan Saudara dengan menghubungi Sekretariat HPI. `)
        } else {
          setIsValidMembership(true)
        }
      }

      setProfileData(result)

      console.log("PROF", result)

    } catch (e) {
      console.log('ERROR' + e)
    }
  }

  const fetchActiveMembership = async () => {
    let memberModel = new Member()
    try {
      let result = await memberModel.getActiveMembership()

      setActiveExtensionData(result.extension)
      setActiveMemberData(result.active)
      setLatestExtensionData(result.latest)

      setLatestHistoryRegardlessOfValidity(result.latestRegardlessOfValidity)

      setGracePeriod(result.grace_period)

      console.log("ACMEM", result)
    } catch (e) {
      console.log('ERROR' + e)
    }
  }

  const fetchMostRecentMemberCard = async () => {
    
    try {
      let result = await MemberCard.getMyMostRecentMemberCard()
      setMemberCardata(result)
    } catch (e) {
      console.log('ERROR' + e)
    }
  }

  const [showReminderDialog, setShowReminderDialog] = useState(false)
  const hasDebtMember = JSON.parse(localStorage.getItem('user'))?.has_debt
  useEffect(() => {
    if (hasDebtMember) {
      setShowReminderDialog(true)
    }

    fetchSelfData()
    fetchActiveMembership()
    fetchMostRecentMemberCard()
  }, [])

  let formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'IDR',
  })
  const [invoiceData, setInvoiceData] = useState([])
  const [showMembership, setShowMemberShip] = useState(false)
  const [showPricesModal, setShowPricesModal] = useState(false)
  const [prices, setPrices] = useState([])
  const [showInvoice, setShowInvoice] = useState(false)
  const [validateMsg, setValidateMsg] = useState('')
  const membershipModels = new MemberShip();

  const fetchInvoice = async () => {
    try {
      let result = await membershipModels.getAllHistory()
      if (result) {
        setInvoiceData(result)
      }
    } catch (e) {

    }
  }

  const fetchPrices = async () => {
    try {
      let result = await membershipModels.getPrices()

      if (result) {
        setPrices(result)
      }
      console.log('hubla', result)

    } catch (e) {
      console.log('error: ', e)
    }
  }

  useEffect(() => {
    fetchInvoice()
    fetchPrices()
  }, [])

  const columns = useMemo(() => {
    let cols = [
      {
        Header: <p style={{fontSize: '0.7em'}}>ID</p>,
        accessor: 'id',
        Cell: (cellInfo) => {
          let value = cellInfo.row.values
          return <p style={{fontSize: '0.7em'}}>{value.id}</p>
        },

      },
      {
        Header: <p style={{fontSize: '0.7em'}}>Dibuat pada</p>,
        accessor: 'transaction',
        Cell: (cellInfo) => {
          let value = cellInfo.row.values
          return <p style={{fontSize: '0.7em'}}>{moment(value.transaction?.created_at).format('DD/MM/YYYY HH:mm')}</p>
        },
      },
      {
        Header: <p style={{fontSize: '0.7em'}}>Jatuh Tempo</p>,
        accessor: 'due_date',
        Cell: (cellInfo) => {
          let value = cellInfo.row.values
          return <p
              style={{fontSize: '0.7em'}}>{moment(value.transaction?.should_expire_at).format('DD/MM/YYYY HH:mm')}</p>
        },
      },
      {
        Header: <p style={{fontSize: '0.7em'}}>Total</p>,
        accessor: 'total',
        Cell: (cellInfo) => {
          let value = cellInfo.row.values
          return <p style={{fontSize: '0.7em'}}>{formatter.format(value.transaction?.amount ?? 0)}</p>
        },
      },
      {
        Header: <p style={{fontSize: '0.7em'}}>Jenis Pembayaran</p>,
        accessor: 'type',
        Cell: (cellInfo) => {
          let value = cellInfo.row.values
          return <p style={{fontSize: '0.6em'}}>{value.transaction? TextTranslator.getTipeProduk(value.transaction?.type) : "Perpanjangan diluar sistem"}</p>
        },
      },
      {
        Header: <p style={{fontSize: '0.7em'}}>Status</p>,
        accessor: 'status',
        Cell: (cellInfo) => {
          let rowInfo = cellInfo.row.values


          let color = 'white'
          let backgroundColor = ''
          let label = '';
          if (rowInfo.status === 'WAITING') {
            backgroundColor = '#2700ff'
            label = 'Menunggu'
          } else if (rowInfo.status === 'CANCELLED') {
            backgroundColor = '#000000'
            label = 'Batal'
          } else if (rowInfo.status === 'ACTIVE' || rowInfo.status === 'PAID' || rowInfo.status === "REPLACED") {
            backgroundColor = '#779500'
            rowInfo.status = "PAID"
            label = 'Lunas'
          }
          return (
              <Badge
                  style={{
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: backgroundColor,
                    paddingTop: 5,
                    paddingBottom: 5,
                    fontSize: '0.7em'
                  }}
                  variant="primary">{label}</Badge>
              // <p
              //     style={{color: color, backgroundColor: backgroundColor}}
              //     className="text-center"
              // >
              //
              // </p>
          )
        },
      },
    ]
    return cols
  })


  let currentValidStatus = () =>{

    let latestHistoryStatus = "ACTIVE"
    if(latestHistoryRegardlessOfValidity){
      console.log("CVS", new Date() < releaseGracePeriod, new Date(latestHistoryRegardlessOfValidity.valid_until) > releaseDate, moment(latestHistoryRegardlessOfValidity.valid_until).add(30, "days").isBefore(new Date()))
      console.log("CVS", releaseDate, releaseGracePeriod, latestHistoryRegardlessOfValidity.valid_until)
      /* if(new Date() < releaseGracePeriod && new Date(latestHistoryRegardlessOfValidity.valid_until) > releaseDate){
        return "RELEASE_GRACE_PERIOD_WARNING"
      } */
      if(moment(latestHistoryRegardlessOfValidity.valid_until).add(90, "days").isBefore(new Date())){
        if(moment().isBefore(GRACE_PERIOD)){
          console.log("GPGP", "JATUH")
          return "JATUH_TEMPO"
        }else{
          console.log("GPGP", "DEBT")
          return "DEBT"
        }
      }
      if(moment(latestHistoryRegardlessOfValidity.valid_until).isBefore(new Date())){
        return "JATUH_TEMPO"
      }
      if(moment(latestHistoryRegardlessOfValidity.valid_until).subtract(30, "days").isBefore(new Date())){
        return "AKAN_JATUH_TEMPO"
      }
    }else{
      return "NON_ACTIVE"
    }
    return latestHistoryStatus

  }

  let membershipExpiryStatus = currentValidStatus()

  if(membershipExpiryStatus === "DEBT"){
    localStorage.removeItem('popUpReminder')
    localStorage.removeItem('user')
  }

  const interactions = [
    {
      condition: (rowInfo) => {
        return !!((rowInfo.transaction?.paid_status === "WAIT_SNAP_NOTIFICATION" || rowInfo.transaction?.paid_status === "PENDING") && rowInfo.transaction?.snap_payment_url);
      },
      name: <div style={{fontSize: '0.7em'}}>Lanjut Bayar</div>,
      action: (rowInfo) => {
        window.open(rowInfo.transaction?.snap_payment_url, "_blank")
      },
    },
    {
      condition: (rowInfo) => {
        return false
        return !!((rowInfo.transaction?.paid_status === "PENDING" || rowInfo.transaction?.paid_status === "WAIT_SNAP_NOTIFICATION") && rowInfo.transaction?.snap_payment_url);
      },
      name: <div style={{fontSize: '0.7em'}}>Change Payment Method</div>,
      action: async (rowInfo) => {
        try {
          let membershipModel = new MemberShip()
          let result = await membershipModel.changeMethod()
          console.log(result)
        } catch (e) {
          console.error(e)
        }

      },
    },
    {
      condition: (rowInfo) => {
        return !!(rowInfo.transaction?.paid_status === "SETTLEMENT" || rowInfo.transaction?.paid_status === "CONFIRMED");
      },
      name: <div style={{fontSize: '0.8em'}}>Lihat Tanda Terima</div>,
      action: (registrant) => {
        console.log(registrant)
        setSelectedInvoiceDetail(registrant)
      },
    },
  ]

  const pricesModal = (
      <Modal
          show={showPricesModal}
          size={'md'}
          onHide={() => setShowPricesModal(false)}
      >
        <Modal.Header closeButton style={{borderBottom: 'none !important', background: '#333c47'}}>
          <p style={{color: '#FFFFFF', fontSize: '1.1em'}}>Peningkatan Status Keanggotaan:<br/><b>
            {profileData?.member?.role}
          </b>
          </p>
        </Modal.Header>

        <Modal.Body>

          <div style={{fontSize: '0.8em'}}>
            <b>
              Jumlah yang dipakai adalah yang dicetak tebal: {profileData?.member?.role}
            </b>
          </div>
          <hr></hr>
          <div style={{fontSize: '0.8em'}} className={'mt-3'}>
            {prices.filter(p=>{
              return p.name === "PENUH" || p.name === "MUDA"
            }).map((p) => {
              return <Row style={{
                color: 'none',
                ...(profileData?.member?.role === p.name && {
                  fontWeight: '700',
                  color: 'black'
                }),
              }}><Col>{p.name}</Col><Col>{formatter.format(p.price)}/tahun</Col></Row>
            })}
          </div>
        </Modal.Body>


        <Modal.Footer>
          <Button onClick={() => {
            continuePayment()
          }}>Lanjutkan</Button>
        </Modal.Footer>
      </Modal>
  )

  const continuePayment = async () => {
    if(moment(latestHistoryRegardlessOfValidity.valid_until).subtract(90, "days").isBefore(new Date())){
      let modelMemberShip = new MemberShip()
      const result = await modelMemberShip.buy()
      window.location.href = result.transaction.snap_payment_url;
    } else {
      Swal.fire({
        title: 'Belum dapat memperpanjang keanggotaan',
        text: 'Keanggotaan anda masih berlaku lebih dari 90 hari lagi, harap coba lagi nanti.',
        icon: 'info',
        confirmButtonText: 'Oke',
      })
    }

    
  }

  const renderStatusKeanggotaan = () => {

    console.log("INVD", invoiceData)

    if(membershipExpiryStatus === "DEBT" || membershipExpiryStatus === "NON_ACTIVE"){
      return <>Status Keanggotaan : <b style={{color: "red"}}>Tidak Aktif</b></>
    }

    return <>Status Keanggotaan : <b style={{color: "green"}}>Aktif</b></>

  }

  return (
      <>
        <ReminderDebtModal close={(is_close) => {
          setShowReminderDialog(false)
        }} isOpen={showReminderDialog}/>

        <Container className="text-left">
          {/* {(!profileData?.member?.hpi_id || !activeMemberData) &&
                    <Row>
                        <Col>
                            <Alert type="error" className='mx-0' errorMsg={'Please activate membership or complete payment'}></Alert>
                        </Col>
                    </Row>} */}
          <Row>
            <Col>
              {
                  !isValidMembership && <Alert errorMsg={validateMsg}/>
              }
            </Col>
          </Row>
          {
              profileData?.member?.membership_creation_status === "WAITING_FIRST_PAYMENT" &&
              <Row>
                <Col>
                  <Alert
                      backgroundColor={"#fff3cd"} color={"#856404"}
                      type="warning" className='mx-0' errorMsg={'Harap menyelesaikan pembayaran pertama untuk melanjutkan proses pendaftaran.'}></Alert>
                </Col>
              </Row>
          }
          {
              profileData?.member?.membership_creation_status === "WAITING_SK" &&
              <Row>
                <Col>
                  <Alert
                      backgroundColor={"#fff3cd"} color={"#856404"}
                      type="warning" className='mx-0' errorMsg={'Kami sedang memproses keanggotaan Saudara.'}></Alert>
                </Col>
              </Row>
          }
          {
              membershipExpiryStatus === "DEBT" &&
              <Row>
                <Col>
                  <Alert
                      // backgroundColor={"#fff3cd"} color={"#856404"}
                      className='mx-0' errorMsg={`Keanggotaan Saudara telah jatuh tempo dan akun Sihapei Saudara telah dinonaktifkan.`}></Alert>
                </Col>
              </Row>
          }
          {
              membershipExpiryStatus === "JATUH_TEMPO" &&
              <Row>
                <Col>
                  <Alert
                      backgroundColor={"#fff3cd"} color={"#856404"}
                      type="warning" className='mx-0' errorMsg={`Keanggotaan Saudara telah jatuh tempo pada ${moment(latestHistoryRegardlessOfValidity?.valid_until).format("DD MMM YYYY")}. Harap segera melakukan perpanjangan keanggotaan sebelum ${moment(gracePeriod).format("DD MMM YYYY")} atau fitur di akun Sihapei Saudara akan dinonaktifkan.`}></Alert>
                </Col>
              </Row>
          }
          {/* {
              membershipExpiryStatus === "RELEASE_GRACE_PERIOD_WARNING" &&
              <Row>
                <Col>
                  <Alert
                      backgroundColor={"#fff3cd"} color={"#856404"}
                      type="warning" className='mx-0' errorMsg={`Keanggotaan Saudara telah jatuh tempo pada ${moment(latestHistoryRegardlessOfValidity?.valid_until).format("DD MMM YYYY")}. Harap segera melakukan perpanjangan keanggotaan sebelum ${moment(releaseGracePeriod).format("DD MMM YYYY")} atau fitur di akun Sihapei Saudara akan dinonaktifkan.`}></Alert>
                </Col>
              </Row>
          } */}
          {
              membershipExpiryStatus === "AKAN_JATUH_TEMPO" &&
              <Row>
                <Col>
                  <Alert
                      backgroundColor={"#fff3cd"} color={"#856404"}
                      type="warning" className='mx-0' errorMsg={`Keanggotaan Saudara akan segera jatuh tempo pada ${moment(latestHistoryRegardlessOfValidity?.valid_until).format("DD MMM YYYY")}. Harap segera melakukan perpanjangan keanggotaan Saudara.`}></Alert>
                </Col>
              </Row>
          }
          {
              profileData?.member?.membership_upgrade_status === "WAITING_SK" &&
              <Row>
                <Col>
                  <Alert
                      backgroundColor={"#fff3cd"} color={"#856404"}
                      type="warning" className='mx-0' errorMsg={'Kami sedang memproses peningkatan status keanggotaan Saudara.'}></Alert>
                </Col>
              </Row>
          }
          {invoiceData && invoiceData.some(i => i.status === 'WAITING' && i.transaction.type === 'UPGRADE_MEMBERSHIP') &&
              <Row>
                <Col>
                  <Alert type="error" className='mx-0' errorMsg={'Harap menyelesaikan pembayaran untuk melanjutkan proses peningkatan status keanggotaan Saudara.'}></Alert>
                </Col>
              </Row>}
          <Row>
            <Col md={6}>
              <Card>
                <Container>

                  <Row>
                    <Col
                        style={{
                          display: "flex",
                          flexDirection: "row"
                        }}
                        md={12}>
                      <img
                          src={profileData?.member?.profile_picture_url ? profileData.member.profile_picture_url : profilePicture}
                          style={{
                            width: 130,
                            objectFit: "contain"
                          }}
                          alt="user"
                      />
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 10
                      }}>
                        <div style={{
                          fontFamily: 'Signika',
                          fontWeight: '600',
                          color: '#8e8e8e',
                        }}>
                          Nama Lengkap
                        </div>
                        <div style={{
                          fontFamily: 'Signika',
                          fontWeight: '600',
                          marginBottom: 15,
                        }}>
                          {profileData?.member?.full_name}
                        </div>
                        <div style={{
                          fontFamily: 'Signika',
                          fontWeight: '600',
                          color: '#8e8e8e',
                        }}>
                          HPI ID
                        </div>
                        <div style={{
                          fontFamily: 'Signika',
                          fontWeight: '600',
                          marginBottom: 15,
                        }}>
                          {profileData?.member?.hpi_id}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>

              </Card>
            </Col>

            <Col
                md={6}>
              <Card style={{
                height: "100%"
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 10,
                  height: "100%"
                }}>
                  <div style={{
                    fontFamily: 'Signika',
                    fontWeight: '600',
                    color: '#8e8e8e',
                  }}>
                    {renderStatusKeanggotaan()}
                  </div>
                  {profileData?.member?.role && (<>
                    <div style={{
                      fontFamily: 'Signika',
                      fontWeight: '600',
                      color: '#8e8e8e',
                    }}>
                      Keanggotaan : <b> {profileData?.member?.role}</b>
                    </div>
                  </>)}
                  {(profileData?.member?.upgrade_status || profileData?.member?.upgrade_status === 'WAITING') && (<>
                    <div style={{
                      fontFamily: 'Signika',
                      fontWeight: '600',
                      color: '#8e8e8e',
                    }}>
                      Upgrade Status : <b style={{color: 'blue'}}>On Progress</b>
                    </div>
                  </>)}
                  <div style={{
                    fontFamily: 'Signika',
                    fontWeight: '600',
                    color: '#8e8e8e',
                  }}>
                    Anggota sejak : <b
                      style={{color: 'black'}}>{moment(profileData?.member?.entry_date).format("DD MMM YYYY")}</b>
                  </div>
                  <div style={{
                    fontFamily: 'Signika',
                    fontWeight: '600',
                    color: '#8e8e8e',
                  }}>
                    Aktif hingga : <b
                      style={{color: 'black'}}>{latestHistoryRegardlessOfValidity ? moment(latestHistoryRegardlessOfValidity?.valid_until).format("DD MMM YYYY") : "-"}</b>
                  </div>
                  {/*<div style={{*/}
                  {/*    fontFamily: 'Signika',*/}
                  {/*    fontWeight: '600',*/}
                  {/*    color: '#8e8e8e',*/}
                  {/*}}>*/}
                  {/*    Last Extension : <b style={{color : 'black'}}>25 Feb 2022</b>*/}
                  {/*</div>*/}
                </div>
              </Card>
            </Col>
          </Row>

          {
              !activeExtensionData &&
              <Button style={{marginTop: 15}} className='mr-1' onClick={() => {
                setShowPricesModal(true)
              }}>
                {
                  activeMemberData ? "Perpanjang Keanggotaan" : "Aktifkan Keanggotaan"
                }
              </Button>
          }

          {
              !!activeMemberData &&
              <>
                {
                  profileData?.member?.sk_url ?
                      <a href={profileData.member.sk_url} target={"_blank"}>
                        <Button
                            // disabled={!profileData.sk_url}
                            style={{marginTop: 15}} className='mr-1'
                        >
                          Unduh SK
                        </Button>
                      </a>
                      :
                      <Button
                          // disabled={!profileData.sk_url}
                          style={{marginTop: 15}} className='mr-1'
                          onClick={() => {
                            console.log(profileData?.member?.sk_url)
                            Swal.fire({
                              title: 'Kami sedang memproses keanggotaan Saudara.',
                              text: 'Harap menunggu beberapa saat. Jika masih belum diproses, harap menghubungi Sekretariat.',
                              icon: 'info',
                              confirmButtonText: 'Oke',
                            })
                          }}
                      >
                        Unduh SK
                      </Button>
                }
              </>
          }

          {
              (profileData?.member?.hpi_id && profileData?.member?.role) === 'MUDA' &&
              <Button style={{marginTop: 15}} className='mr-1' onClick={() => {
                switch (profileData?.member?.membership_upgrade_status) {
                  case "WAITING":
                    Swal.fire('Menunggu persetujuan dari Sekretariat', 'Saat ini pengajuan Saudara sedang kami tinjau, mohon menunggu persetujuan dari Sekretariat.', 'info')
                    break;

                  case "APPROVED":
                    Swal.fire('Keanggotaan Saudara telah disetujui', 'Harap selesaikan pembayaran untuk menyelesaikan peningkatan status keanggotaan.', 'info')
                    break;

                  case "REJECTED":
                    Swal.fire({
                      title: "Pengajuan peningkatan status Saudara ditolak.",
                      text: `Mohon maaf, peningkatan status Saudara kami tolak karena: ${profileData.member.membership_upgrade_rejected_reason}`,
                      icon: 'error',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Coba Lagi',
                      cancelButtonText: 'Batal'
                    }).then((result) => {
                      if (result.isConfirmed) {

                        setProfileData({...profileData, member: {...profileData.member, membership_upgrade_status: null}})
                        setShowUpgradeModal(true)
                      }
                    })
                    break;

                  default:
                    setShowUpgradeModal(true)
                    break;
                }
              }}>Peningkatan Status Keanggotaan
                {profileData.member.membership_upgrade_status === 'REJECTED' &&
                    <span class="ml-1 badge badge-pill badge-danger">!</span>}
              </Button>
          }

          {
            profileData?.member?.hpi_id && <Button style={{marginTop: 15}} onClick={() => {
              setShowMemberCard(true)
            }} variant='outline-info'>Lihat Kartu Anggota
            </Button>
          }


          <MembershipModal
              data={prices.filter((p) => p.name === profileData?.member?.role)}
              invoiceData={invoiceData}
              displayModal={showMembership}
              toogleSelected={showMembership => setShowMemberShip(false)}
          />
          {pricesModal}
          <Row style={{marginBottom: 15}}>

            <Col>
              <MobTable columns={columns} data={invoiceData} interactions={interactions}
                        interactionsLabelStyles={{fontSize: '0.7em'}}/>
            </Col>
          </Row>
        </Container>

        <InvoiceDetailModal
            profile={profileData}
            show={!!selectedInvoiceDetail}
            close={() => setSelectedInvoiceDetail(null)}
            data={selectedInvoiceDetail}
        />

        <MemberCardModal
            data={latestExtensionData ? {...profileData, latestExtensionData, ...profileData.member, member_card: memberCarData} : profileData}
            show={showMemberCard}
            close={() => setShowMemberCard(false)}/>

        <UpgradeMembershipModal
            data={profileData}
            show={showUpgradeModal}
            close={() => setShowUpgradeModal(false)}/>
      </>
  )
}

export default InvoicePage
